import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

import CardAnimationWrapper from 'components/cards/CardAnimationWrapper'
import ImageCard from './ImageCard'

const CardsList = ({ priorities }) => {
  const isSeeAll = useSelector(path(['priorities', 'isSeeAll']))

  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: isSeeAll ? 'wrap' : 'unset',
        gap: 1.5,
        justifyContent: isSeeAll ? 'center' : 'flex-start',
        overflowX: isSeeAll ? 'unset' : 'auto'
      }}
    >
      {priorities.map(({ display_image, id, title, link }) => (
        <CardAnimationWrapper key={id}>
          <ImageCard imgURL={display_image} link={link} title={title} />
        </CardAnimationWrapper>
      ))}
    </Stack>
  )
}

CardsList.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default CardsList
