import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  isLoggedOutOpen: false,
  isSidebarOpen: false
}

const sidebarSlice = createSlice({
  initialState,
  name: 'sidebar',
  reducers: {
    setIsLoggedOutOpen: (state, { payload }) =>
      assoc('isLoggedOutOpen', payload, state),
    setIsSidebarOpen: (state, { payload }) =>
      assoc('isSidebarOpen', payload, state)
  }
})

export const { setIsLoggedOutOpen, setIsSidebarOpen } = sidebarSlice.actions

export default sidebarSlice.reducer
