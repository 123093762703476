import Icon from 'components/icon/Icon'
import {
  equals,
  prop,
  isNil,
  path,
  defaultTo,
  all,
  replace,
  toLower,
  compose,
  or
} from 'ramda'

export const slugify = str =>
  str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^\w-]+/g, '')

export const isFacultyExperience = (user, school) => {
  const secondaryAppTypeFeaturesEnabled = prop(
    'enable_secondary_user_type_features',
    school
  )
  const secondaryAppType = defaultTo(null, path(['app_type', 'slug'], user))
  const userType = prop('user_type', user)
  const checks = [
    secondaryAppTypeFeaturesEnabled,
    equals(secondaryAppType, 'faculty'),
    equals(userType, 'secondary')
  ]

  return all(equals(true))(checks)
}

export const truncateLines = num => ({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: `${num}`,
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal'
})

export const subdomainsFromUserDomains = domains => {
  if (isNil(domains)) return
  // domains needs to be converted to a JS object using toJS() for this to work
  return domains.reduce(
    (subdomains, domain) => domain.subdomains.concat(subdomains),
    []
  )
}

// convert ampersand to and
// remove any illegal url characters
// replace the space with - character
// return all to lower case
export const sanitizeUrl = compose(
  toLower,
  replace(/ /g, '-'),
  replace(/[^a-zA-Z0-9 ]/g, ''),
  replace(/@/g, 'at'),
  replace(/&/g, 'and')
)

// Icon Type for different sections
export const iconType = domainId => {
  if (equals(1, domainId)) return <Icon fill>potted_plant</Icon>
  if (equals(2, domainId)) return <Icon fill>work</Icon>
  return <Icon fill>diversity_1</Icon>
}

// Domain coloring
// Depending on Student or Staff/Fac
export const domainColor = domainId => {
  if (or(equals(1, domainId), equals(14, domainId))) return '#00866C'
  if (or(equals(2, domainId), equals(12, domainId))) return '#002E32'
  if (or(equals(3, domainId), equals(13, domainId))) return '#1D1528'
}
