import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  isConfirmationModalOpen: false,
  isCrisisModalOpen: false,
  isEmailModalOpen: false
}

const modalSlice = createSlice({
  initialState,
  name: 'modal',
  reducers: {
    setIsConfirmationModalOpen: (state, { payload }) =>
      assoc('isConfirmationModalOpen', payload, state),
    setIsCrisisModalOpen: (state, { payload }) =>
      assoc('isCrisisModalOpen', payload, state),
    setIsEmailModalOpen: (state, { payload }) =>
      assoc('isEmailModalOpen', payload, state)
  }
})

export const {
  setIsConfirmationModalOpen,
  setIsCrisisModalOpen,
  setIsEmailModalOpen
} = modalSlice.actions

export default modalSlice.reducer
