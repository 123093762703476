import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useAnalytics } from 'hooks'
import { and, any, equals, isEmpty, isNil, not, path, prop } from 'ramda'
import { truncateLines } from 'utils/helpers'
import parse from 'html-react-parser'
import { useFavoriteMutation } from 'api/cardsApi'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'
import { Link, useLocation } from 'react-router-dom'
import { internalUrl } from 'components/cards/helpers'
import { TYPE_FACT_TIP } from 'utils/card-functions'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ResourceMask from './ResourceMask'
import CardType from './CardType'
import ImageIcon from './ImageIcon'
import Icon from 'components/icon/Icon'

const ContentCard = ({ card = null }) => {
  // Utils
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const { id: schoolId, name } = useSelector(prop('school'))

  const [favorite] = useFavoriteMutation()

  // Card Variables
  const title = prop('title', card)
  const photoDescription = prop('photo_description', card)
  const videoDescription = prop('video_description', card)
  const campusResource = prop('campus_resource', card)
  const isFavorite = prop('is_favorite', card)
  const id = prop('id', card)
  const type = prop('type', card)

  const photo = useMemo(() => {
    if (equals(TYPE_FACT_TIP, type)) return '/vectors/fact-tip-card-bg.svg'
    return path(['photo', 'large_url'], card) ?? prop('video_thumb_url', card)
  }, [card, type])

  const params = useMemo(
    () => ({
      'App Type': 'school',
      'Campus Resource': prop('campus_resource', card),
      'Card Domain': prop('domain_name', card),
      'Card Id': prop('id', card),
      'Card Name': prop('title', card).replace('"', ''),
      'Card Type': convertCardTypeToString(prop('type', card)),
      Path: pathname,
      'School Id': schoolId,
      'School Name': name
    }),
    [card, pathname, schoolId, name]
  )

  if (isNil(card)) return null

  // Helper functions
  // TODO: Leave for now if we have issues with Fact cards
  // const getCleanDescription = (desc, length) => {
  //   if (isNil(desc)) return
  //   const split = desc.substring(0, length).split(' ')
  //   const splitpop = split.splice(0, split.length - 1)
  //   return splitpop.join(' ')
  // }

  const cleanLinkTag = str => {
    if (isNil(str)) return
    return str.replace(/<(.|\n)*?>/g, '')
  }

  const articleDescription = () => {
    if (equals(type, TYPE_FACT_TIP)) return prop('facts_tips_copy', card)
    if (and(not(isNil(videoDescription)), not(isEmpty(videoDescription))))
      return cleanLinkTag(videoDescription)
    if (and(not(isNil(photoDescription)), not(isEmpty(photoDescription))))
      return cleanLinkTag(photoDescription)
    if (
      any(equals(true))([
        isNil(videoDescription),
        isEmpty(videoDescription),
        isNil(photoDescription),
        isEmpty(photoDescription)
      ])
    )
      return ''
  }

  const handleFavorite = () => {
    favorite({
      card_id: id,
      isFavorite: not(isFavorite)
    })
    if (not(isFavorite)) {
      sendEvent('card_detail_view_favorite', { ...params })
    } else {
      sendEvent('card_detail_view_unfavorite', { ...params })
    }
  }

  const handleClick = () => sendEvent('card_detail_view', { ...params })

  return (
    <Card>
      <CardActionArea
        LinkComponent={Link}
        onClick={handleClick}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
        to={internalUrl(card)}
      >
        <Box
          sx={{
            backgroundImage: `url(${photo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            height: 180,
            position: 'relative',
            width: 1
          }}
        >
          {campusResource ? <ResourceMask /> : <ImageIcon type={type} />}
        </Box>
        <CardContent sx={{ pb: 0 }}>
          <Stack spacing={1}>
            <CardType
              campusResource={campusResource}
              type={type}
              videoDescription={videoDescription}
            />
            <Typography variant="h4">{title}</Typography>
            <Typography
              component="div"
              sx={{
                ...truncateLines(3),
                '& > p': {
                  margin: 0,
                  padding: 0
                }
              }}
              variant="body2"
            >
              {parse(articleDescription())}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          justifyContent: 'flex-end',
          pb: 1,
          pt: 0,
          px: 1
        }}
      >
        <IconButton
          aria-label={`Bookmark ${isFavorite ? 'selected' : 'unselected'}`}
          onClick={handleFavorite}
        >
          <Icon fill={isFavorite}>bookmark</Icon>
        </IconButton>
      </CardActions>
    </Card>
  )
}

ContentCard.propTypes = {
  card: PropTypes.shape({}).isRequired
}

export default ContentCard
