/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { and, equals, inc, lt, not, prop } from 'ramda'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setIsSpotlightOpen, setType } from './spotlightSlice'
import useGetHelperList from './useGetHelperList'
import { setIsSidebarOpen } from 'components/sidebar/sidebarSlice'
import { useAnalytics } from 'hooks'

import Icon from 'components/icon/Icon'

const IntroductionContent = ({ hasHelperList = false }) => {
  const dispatch = useDispatch()
  const { currentStep } = useSelector(prop('spotlight'))
  const { letters } = useSelector(prop('school'))
  const { is_secondary_user } = useSelector(prop('user'))
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))
  const list = useGetHelperList()
  const { sendEvent } = useAnalytics()

  const title = useMemo(() => {
    if (equals(0, currentStep)) return 'Discover Content'
    if (equals(1, currentStep)) return 'Your Progress'
    if (equals(2, currentStep)) return 'Your Campus Connection'
    if (equals(3, currentStep)) return 'All About YOU'
    if (equals(4, currentStep)) return 'Your Priorities'
    if (equals(5, currentStep)) return 'Check In'
    if (equals(6, currentStep)) return 'Personalize It'
  }, [currentStep])

  const subtitle = useMemo(() => {
    if (equals(0, currentStep))
      return `Navigate the huge YOU at ${letters} database to discover the topics and tools that best match your interests.`
    if (equals(1, currentStep))
      return 'Keep track of everything you find and follow through on your goals.'
    if (equals(2, currentStep))
      return `Use these school-specific resources to get the most out of your time at ${letters}.`
    if (equals(3, currentStep))
      return `Here’s where you’ll find a snapshot of your Self Checks${is_secondary_user ? '' : ', Goals,'} and Favorites.`
    if (equals(4, currentStep))
      return 'What’s most important to YOU? Choose topics as your Priorities, and they’ll show up here as shortcuts.'
    if (equals(5, currentStep))
      return 'How are you feeling right here, right now? Jump straight to the most relevant content with one click.'
    if (equals(6, currentStep)) return `Make YOU at ${letters} your own!`
  }, [currentStep, letters, is_secondary_user])

  const handleReset = () => {
    dispatch(setIsSpotlightOpen(false))
    dispatch(setType(''))
    dispatch(setCurrentStep(0))
  }

  const handleClose = () => {
    // Basically if they ever leave before track it
    if (not(equals(6, currentStep))) {
      sendEvent('Spotlight_tour', {
        Event: 'Leave',
        Step: inc(currentStep)
      })
      return handleReset()
    }
    sendEvent('Spotlight_tour', {
      Event: 'Finish'
    })
    return handleReset()
  }

  const handleNext = () => {
    if (and(isMobile, equals(2, currentStep))) dispatch(setIsSidebarOpen(false))
    if (and(isMobile, equals(5, currentStep))) dispatch(setIsSidebarOpen(true))
    if (equals(6, currentStep)) {
      dispatch(setIsSidebarOpen(false))
      return handleClose()
    }
    return dispatch(setCurrentStep(inc(currentStep)))
  }

  return (
    <Stack
      sx={{
        maxWidth: { mobile: 'unset', laptop: 490 },
        minWidth: { mobile: 'unset', laptop: 490 },
        px: 2,
        py: 2.5,
        width: 1
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{subtitle}</Typography>
        {hasHelperList &&
          prop(currentStep, list)?.map(
            ({ icon, isShown = true, subtitle, title }) =>
              isShown && (
                <Stack direction="row" key={title} spacing={1.5}>
                  <Icon fill>{icon}</Icon>
                  <Stack spacing={0.5}>
                    <Typography variant="overline">{title}</Typography>
                    <Typography variant="body1">{subtitle}</Typography>
                  </Stack>
                </Stack>
              )
          )}
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mt: 3 }}
      >
        <Typography variant="subtitle2">{inc(currentStep)}/7</Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          {lt(currentStep, 6) && (
            <Button onClick={handleClose} variant="outlined">
              End Tour
            </Button>
          )}
          <Button endIcon={<Icon>arrow_forward</Icon>} onClick={handleNext}>
            {equals(6, currentStep) ? 'Done' : 'Got It'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

IntroductionContent.propTypes = {
  hasHelperList: PropTypes.bool
}

export default IntroductionContent
