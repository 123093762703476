/* eslint-disable sort-keys */
import { Box, Button, Divider, Stack } from '@mui/material'
import { prop, equals, not, isNil, path } from 'ramda'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CrisisButton from 'components/buttons/CrisisButton'
import ProfileItem from 'components/sidebar/ProfileItem'
import LinkList from './LinkList'
import SkipButton from 'components/header/SkipButton'
import SpotlightWrapper from 'components/spotlight/SpotlightWrapper'

const LoggedInSidebar = () => {
  const mode = useSelector(path(['theme', 'mode']))
  const { logoImageDarkMode, logoImageLightMode } = useSelector(prop('school'))
  const { id } = useSelector(prop('user'))
  const isLoggedIn = not(isNil(id))

  return (
    <>
      <Stack
        sx={{
          backgroundColor: 'surface.low',
          position: 'sticky',
          px: 1.5,
          top: 0,
          width: 1,
          zIndex: 2
        }}
      >
        <SkipButton />
        <Box
          aria-label="home"
          component={Button}
          LinkComponent={Link}
          sx={{
            background: `url(${equals('dark', mode) ? logoImageDarkMode : logoImageLightMode})`,
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: 36,
            mb: 3,
            p: 0,
            width: 1
          }}
          to={isLoggedIn ? '/home' : '/'}
          variant=""
        />
        <Stack
          sx={{
            '& > button': {
              width: 1
            },
            mb: 1.5,
            width: 1
          }}
        >
          <CrisisButton />
        </Stack>
      </Stack>
      <Stack
        justifyContent="space-between"
        sx={{
          height: 1,
          overflowY: 'auto',
          width: 1
        }}
      >
        <LinkList />
        <Stack
          sx={{
            width: 1
          }}
        >
          <Divider />
          <SpotlightWrapper
            hasHelperList
            placement={{ mobile: 'bottom-start', laptop: 'right-start' }}
            spotlightType="introduction"
            step={6}
          >
            <Stack spacing={1}>
              <ProfileItem />
            </Stack>
          </SpotlightWrapper>
        </Stack>
      </Stack>
    </>
  )
}

export default LoggedInSidebar
