import { Box, Button, Stack, Typography, Zoom } from '@mui/material'
import { format } from 'date-fns'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { isNil, not, toLower } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const SelfCheckItem = ({
  domainUrlSlug,
  isCompleted,
  lastCompleted,
  subtitle,
  title
}) => {
  const { sendEvent } = useAnalytics()

  return (
    <Zoom in>
      <Stack direction="row" spacing={1.5}>
        <Box
          height={98}
          role="presentation"
          sx={{
            backgroundImage: `url(/vectors/${toLower(title)}${not(isCompleted) ? '-bw' : ''}.svg)`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
          width={119}
        />
        <Stack spacing={1}>
          <Stack>
            <Typography textTransform="capitalize" variant="subtitle1">
              {subtitle}
            </Typography>
            {not(isNil(lastCompleted)) && (
              <Typography variant="caption">
                Last taken {format(lastCompleted, 'MM/dd/yy')}
              </Typography>
            )}
          </Stack>
          {isCompleted ? (
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('Stats_bar_self_checks', {
                  Domain: title,
                  'Element Title': 'View Results'
                })
              }
              to={`/self-checks/results/${domainUrlSlug}`}
            >
              <FormattedMessage defaultMessage="View results" id="WI1nyU" />
            </Button>
          ) : (
            <Button
              LinkComponent={Link}
              onClick={() =>
                sendEvent('Stats_bar_self_checks', {
                  Domain: title,
                  'Element Title': 'Take Assessment'
                })
              }
              to={`/self-checks/${domainUrlSlug}`}
            >
              take assessment
            </Button>
          )}
        </Stack>
      </Stack>
    </Zoom>
  )
}

SelfCheckItem.propTypes = {
  domainUrlSlug: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  lastCompleted: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default SelfCheckItem
