/* eslint-disable sort-keys */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { not, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import {
  setIsSpotlightOpen,
  setType
} from 'components/spotlight/spotlightSlice'
import { setIsSidebarOpen } from 'components/sidebar/sidebarSlice'
import Icon from 'components/icon/Icon'

const ActionButton = () => {
  const dispatch = useDispatch()
  const { letters } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  const [isOpen, setIsOpen] = useState(false)

  const handleAnalytics = title =>
    sendEvent('Help_modal', {
      'Element Title': title
    })

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <Dialog
        aria-labelledby="dialog-title"
        onClose={() => {
          setIsOpen(false)
          handleAnalytics('Close Modal')
        }}
        open={isOpen}
        PaperProps={{
          sx: {
            bottom: { mobile: 48, laptop: 0 },
            position: 'absolute',
            right: { mobile: -20, laptop: 72 }
          }
        }}
        role="alertdialog"
      >
        <DialogTitle
          component="h1"
          id="dialog-title"
          sx={{ fontSize: 20, mt: 2, textAlign: 'left' }}
          tabIndex={-1}
        >
          YOU at {letters}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: { mobile: 2, laptop: 3 }
          }}
        >
          <Typography variant="subtitle2">How can we help?</Typography>
          <Card>
            <CardActionArea
              onClick={() => {
                setIsOpen(false)
                if (isMobile) dispatch(setIsSidebarOpen(true))
                setTimeout(() => {
                  dispatch(setIsSpotlightOpen(true))
                  dispatch(setType('introduction'))
                }, 500)
                handleAnalytics('Take tour')
              }}
            >
              <CardContent>
                <Typography variant="h5">Take a Guided Tour</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography variant="body2">
                    Let us walk you through how to get the most out of You at
                    College.
                  </Typography>
                  <Icon>arrow_forward</Icon>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card>
            <CardActionArea
              LinkComponent={Link}
              onClick={() => {
                setIsOpen(false)
                handleAnalytics('Give Feedback')
              }}
              to="/feedback"
            >
              <CardContent>
                <Typography variant="h5">Give Feedback</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography variant="body2">
                    Do you have feedback about your experience using the site or
                    a question for the You at College team? Let us hear it!
                  </Typography>
                  <Icon>arrow_forward</Icon>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </DialogContent>
      </Dialog>
      <Fab
        aria-label="help"
        onClick={() => {
          setIsOpen(not(isOpen))
          handleAnalytics(isOpen ? 'Close Modal' : 'Open Modal')
        }}
        sx={{
          backgroundColor: 'primary.tonalContainer',
          bottom: { mobile: 16, laptop: 32 },
          position: 'fixed',
          right: { mobile: 16, laptop: 32 },
          // To have it above dialog overlay
          zIndex: 1301
        }}
      >
        {isOpen ? <Icon>close</Icon> : <Icon>question_mark</Icon>}
      </Fab>
    </Box>
  )
}

export default ActionButton
