/* eslint-disable sort-keys */
import { Button, Divider, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { and, equals, isNil, not, or, prop } from 'ramda'
import { useGetSchoolName } from 'hooks'

import SidebarMenuItem from './SidebarMenuItem'
import ReferralLinks from './ReferralLinks'
import Icon from 'components/icon/Icon'
import SpotlightWrapper from 'components/spotlight/SpotlightWrapper'

const LinkList = () => {
  const navigate = useNavigate()
  const {
    allowed_to_view_stats_endpoint,
    app_type,
    hide_resources,
    id,
    is_secondary_user,
    location_id
  } = useSelector(prop('user'))
  const { enable_goals, enable_tips, tips_data } = useSelector(prop('school'))
  const tipsTitle = prop('tips_category_title', tips_data)

  //GOALS
  const showGoalsForPrimaryUser = and(not(is_secondary_user), enable_goals)
  const showGoalsForSecondaryUser = and(
    is_secondary_user,
    prop('enable_goals', app_type)
  )
  const showGoals = or(showGoalsForPrimaryUser, showGoalsForSecondaryUser)

  //LOCATION
  const locationName = useGetSchoolName(location_id)

  //STATS
  const canViewStats = and(
    not(isNil(id)),
    equals(allowed_to_view_stats_endpoint, 1)
  )

  return (
    <Stack
      sx={{
        px: 1.5,
        width: 1
      }}
    >
      <SidebarMenuItem
        icon="home"
        link="/home"
        title={<FormattedMessage defaultMessage="Home" id="ejEGdx" />}
      />
      <SpotlightWrapper
        hasHelperList
        placement={{ mobile: 'bottom-start', laptop: 'right-start' }}
        spotlightType="introduction"
        step={0}
      >
        <SidebarMenuItem
          icon="search"
          link="/search"
          title={<FormattedMessage defaultMessage="Search" id="xmcVZ0" />}
        />
        <SidebarMenuItem
          icon="explore"
          link="/explore"
          title={<FormattedMessage defaultMessage="Explore" id="7JlauX" />}
        />
        <SidebarMenuItem
          icon="contract_edit"
          isShown={and(enable_tips, not(is_secondary_user))}
          link="/student-posts"
          title={tipsTitle}
        />
        <SidebarMenuItem
          icon="note_stack"
          isShown={is_secondary_user}
          link="/student-content"
          title={<FormattedMessage defaultMessage="For Students" id="vDwbU8" />}
        />
      </SpotlightWrapper>
      <SpotlightWrapper
        hasHelperList
        placement={{ mobile: 'bottom-start', laptop: 'right-start' }}
        spotlightType="introduction"
        step={1}
      >
        <SidebarMenuItem
          icon="spa"
          link="/self-checks"
          title={<FormattedMessage defaultMessage="Self Checks" id="OB/ZtU" />}
        />
        <SidebarMenuItem
          icon="mountain_flag"
          isShown={showGoals}
          link="/goals?active=explore"
          title={<FormattedMessage defaultMessage="Goals" id="XHLYdJ" />}
        />
        <SidebarMenuItem
          icon="bookmark"
          link="/favorites"
          title={<FormattedMessage defaultMessage="Favorites" id="SMrXWc" />}
        />
      </SpotlightWrapper>
      <SidebarMenuItem
        icon="data_exploration"
        isShown={canViewStats}
        link="/stats-dashboard"
        title={<FormattedMessage defaultMessage="Stats" id="U86B6w" />}
      />
      <Divider />
      <SpotlightWrapper
        placement={{ mobile: 'bottom-start', laptop: 'right-start' }}
        spotlightType="introduction"
        step={2}
      >
        <SidebarMenuItem
          icon="location_on"
          isShown={not(hide_resources)}
          link="/resources"
          title={
            <FormattedMessage
              defaultMessage="{locationName} Resources"
              id="6cfbB5"
              values={{ locationName }}
            />
          }
        />
        <ReferralLinks />
      </SpotlightWrapper>
      <Stack>
        <Divider />
        <Button
          onClick={() => navigate('/logout')}
          startIcon={<Icon>logout</Icon>}
          variant="sidebar"
        >
          <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default LinkList
