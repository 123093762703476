import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  currentStep: 0,
  isSpotlightOpen: false,
  type: ''
}

const spotlightSlice = createSlice({
  initialState,
  name: 'spotlight',
  reducers: {
    setCurrentStep: (state, { payload }) =>
      assoc('currentStep', payload, state),
    setIsSpotlightOpen: (state, { payload }) =>
      assoc('isSpotlightOpen', payload, state),
    setType: (state, { payload }) => assoc('type', payload, state)
  }
})

export const { setCurrentStep, setIsSpotlightOpen, setType } =
  spotlightSlice.actions

export default spotlightSlice.reducer
