import {
  Button,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { useGetFeaturedCardsQuery } from 'api/cardsApi'
import { useGetFeaturedContentQuery } from 'api/schoolApi'
import CardHandler from 'components/cards/CardHandler'
import { useAnalytics } from 'hooks'
import {
  all,
  and,
  any,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  not,
  or,
  prop
} from 'ramda'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Featured = () => {
  const { sendEvent } = useAnalytics()
  const { domain, letters } = useSelector(prop('school'))
  const {
    is_secondary_user: isSecondaryUser,
    location_id: location,
    isSuccess
  } = useSelector(prop('user'))

  const { data = [], isFetching = true } = useGetFeaturedCardsQuery(
    `?org_domain=${domain}&is_staff_faculty=${isSecondaryUser ? 1 : 0}&location_id=${location}`,
    {
      skip: any(equals(true))([
        equals(isSuccess, false),
        isNil(domain),
        isNil(location),
        isNil(isSecondaryUser)
      ])
    }
  )

  const { data: featuredContent = {}, isFetching: isFetchingGroups = true } =
    useGetFeaturedContentQuery()

  if (or(isFetching, isFetchingGroups))
    return (
      <Card
        sx={{
          backgroundColor: 'surface.low',
          borderTop: '12px solid',
          borderTopColor: 'brand.iceBlue',
          width: 1
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4
          }}
        >
          <Stack alignItems="center" spacing={3}>
            <Skeleton height={32} variant="text" width={235} />
            <Skeleton height={94} variant="rectangle" width="100%" />
          </Stack>
          <Skeleton height={110} variant="rectangle" width="100%" />
        </CardContent>
      </Card>
    )

  if (
    all(equals(true))([
      not(isFetching),
      not(isFetchingGroups),
      isEmpty(data),
      isEmpty(prop('groups', featuredContent))
    ])
  )
    return

  return (
    <Card
      sx={{
        backgroundColor: 'surface.low',
        borderTop: '12px solid',
        borderTopColor: 'brand.iceBlue',
        width: 1
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
      >
        {and(not(isFetching), gt(length(data), 0)) && (
          <Stack alignItems="center" spacing={3}>
            <Typography variant="h2">Featured at {letters}</Typography>
            <Stack
              direction="row"
              sx={{
                flexWrap: 'wrap',
                gap: 4,
                // eslint-disable-next-line sort-keys
                justifyContent: { mobile: 'center', laptop: 'unset' }
              }}
            >
              {data.map(({ card }) => (
                <CardHandler
                  card={card}
                  isFeatured
                  key={prop('id', card)}
                  small
                />
              ))}
            </Stack>
          </Stack>
        )}
        {and(
          not(isFetchingGroups),
          gt(length(prop('groups', featuredContent)), 0)
        ) && (
          <Stack
            spacing={2}
            sx={{
              backgroundColor: 'surface.container',
              borderRadius: 2,
              p: 2,
              width: 1
            }}
          >
            <Typography variant="subtitle1">Special Topics</Typography>
            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
              {prop('groups', featuredContent).map(
                ({ display_text, id, name }) => (
                  <Button
                    key={id}
                    LinkComponent={Link}
                    onClick={() =>
                      sendEvent('featured_topic', {
                        'Card Name': display_text,
                        'Element Title': 'Featured Topic',
                        'Topic Name': name
                      })
                    }
                    to={`/explore/group/${id}`}
                  >
                    {display_text}
                  </Button>
                )
              )}
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}

export default Featured
